@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;0,800;1,400;1,600;1,700;1,800&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

* {
  box-sizing: border-box;
}

:root {
  --light: #f6f1f1;
  --light-30: #f6f1f14d;
  --dark: #463a37;
  --primary: #a87c4f;
}

body {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light);
}

.container {
  margin-left: 140px;
  margin-right: 140px;
}

.btn {
  padding: 20px 33px;
  border-radius: 50px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn:active {
  opacity: 0.7;
}

.btn-trans {
  background-color: var(--light-30);
}

.btn-trans:hover {
  background-color: var(--primary);
}

.btn-group {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: auto;
}

.btn-group .btn {
  margin-right: 20px;
}

.btn-group .btn:last-of-type {
  margin-right: 0;
}

.btn-primary {
  background-color: var(--primary);
  color: white;
}

.btn-ghost {
  border: solid 1px white;
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Playfair Display', serif;
}

h1 {
  font-size: 48px;
  line-height: 53px;
}

h2 {
  font-size: 40px;
  line-height: 132.5%;
}

h3 {
  font-size: 32px;
  line-height: 24px;
}

p {
  line-height: 150%;
  opacity: 0.7;
}

a {
  color: unset;
  text-decoration: unset;
}

@media all and (max-width: 768px) {
  .container {
    width: 90%;
    margin-left: 0;
    margin-right: 0;
    margin: auto;
  }

  h1 {
    font-size: 44px;
  }

  h2 {
    font-size: 36px;
    line-height: 110%;
  }

  p {
    line-height: 175%;
  }
}
