.hero {
  background-image: url('../../Assets/hero-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 70px;
}

.hero .container {
  text-align: center;
}

.hero-content {
  color: white;
  max-width: 600px;
  margin: auto;
}

.hero-content h1 {
  margin-bottom: 20px;
}

.sub-title {
  margin-bottom: 30px;
}

@media all and (max-width: 768px) {
  .hero {
    margin-bottom: 0;
  }

  .hero .btn {
    padding: 20px 28px;
  }
}
