.footer {
  background-color: var(--dark);
  color: white;
  padding: 35px 0;
}

.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer a {
  transition: filter 0.3s ease;
  user-select: none;
}

.footer a:hover {
  filter: invert(50%) sepia(59%) saturate(343%) hue-rotate(349deg) brightness(91%) contrast(88%);
}

.footer a:active {
  opacity: 0.7;
}

@media all and (max-width: 768px) {
  .footer .container {
    flex-direction: column-reverse;
  }

  .copyright {
    font-size: 14px;
    margin-top: 17px;
  }
}
