.customers {
  padding-top: 70px;
  padding-bottom: 70px;
}

.customers .container {
  display: flex;
}

.customers h2 {
  margin-bottom: 18px;
}

.customers-content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.customers .photos-container {
  position: relative;
  min-width: 818px;
  max-width: 818px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  left: 68px;
}

.customers .slider {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 684px;
  max-width: 684px;
  scroll-behavior: smooth;
}

.customers .slider::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.customers .photo-wrapper {
  min-width: 240px;
  max-width: 240px;
  height: 300px;
  margin-right: 18px;
  border-radius: 5px;
}

.customers .photo-wrapper:last-of-type {
  margin-right: 0;
}

.customers .photo-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.customers .pn-btn {
  margin-bottom: 16px;
  cursor: pointer;
  position: relative;
  left: 0;
  transition: left 0.3s ease;
}

.customers .pn-btn:active {
  opacity: 0.7;
}

.customers .prev-btn {
  transform: rotate(180deg);
}

.customers .prev-btn:hover {
  left: -3px;
}

.customers .next-btn:hover {
  left: 3px;
}

@media all and (max-width: 768px) {
  .customers {
    padding-bottom: 0;
  }

  .customers .container {
    flex-direction: column;
  }

  .customers .photos-container,
  .customers .slider {
    min-width: 100%;
    max-width: 100%;
  }

  .customers .pn-btn {
    display: none;
  }

  .customers .photos-container {
    left: 0;
  }

  .customers-content {
    /* padding: 5%; */
    /* text-align: right; */
  }
}
