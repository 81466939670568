.map-section {
  padding-top: 70px;
  padding-bottom: 70px;
}

.map-section .container {
  display: flex;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02), 0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05), 0 100px 80px rgba(0, 0, 0, 0.07);
}

.map-section h3 {
  color: var(--dark);
}

.map-container {
  width: 100%;
  max-width: 545px;
  height: 545px;
}

#map {
  width: 100%;
  height: 100%;
  border-radius: 0 5px 5px 0;
}

.contactDetails {
  flex-grow: 1;
  padding: 0 86px;
  max-height: 545px;
  display: flex;
  align-items: center;
}

.oneDetail {
  margin-bottom: 70px;
}

.oneDetail p {
  max-width: 228px;
  margin-top: 17px;
}

.oneDetail:last-of-type {
  margin-bottom: 0;
}

.map-section a {
  transition: color 0.3s ease;
}

.map-section a:hover {
  color: var(--primary);
}

.map-section a:active {
  opacity: 0.7;
}

@media all and (max-width: 768px) {
  .map-section .container {
    flex-direction: column;
    overflow: hidden;
  }

  .contactDetails {
    padding: 15% 10%;
  }

  .oneDetail {
    margin-bottom: 55px;
  }

  #map {
    border-radius: 0 0 5px 5px;
  }
}
