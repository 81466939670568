.more-than {
  padding: 70px 0;
}

.more-than .container {
  display: flex;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02), 0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05), 0 100px 80px rgba(0, 0, 0, 0.07);
  overflow: hidden;
}

.square {
  width: 100%;
  max-width: 545px;
  height: 545px;
  background: url('../../Assets/more-than.jpg');
  background-size: cover;
  background-position: top;
}

.rest {
  padding: 80px;
}

.rest h2 {
  margin-bottom: 20px;
  position: relative;
  margin-left: -5px;
}

.rest p {
  margin-top: 15px;
}

@media all and (max-width: 768px) {
  .more-than .container {
    flex-direction: column;
  }

  .rest {
    padding: 10%;
  }

  .rest h2 {
    position: relative;
    margin-left: -1px;
  }

  .more-than .container {
    box-shadow: unset;
  }
}
