.navbar {
  padding: 35px 0;
  position: fixed;
  z-index: 9;
  top: 0;
  width: 100vw;
  background-color: transparent;
  transition: padding 0.3s ease, background-color 0.3s ease;
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .btn {
  font-size: 14px;
}

.link-container {
  display: flex;
  align-items: center;
}

.nav-link {
  color: white;
  margin-right: 50px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  transition: color 0.1s ease;
}

.nav-link:hover {
  color: var(--primary);
}

.nav-link:active {
  opacity: 0.7;
}

.nav-brand {
  font-family: 'Playfair Display', serif;
  font-size: 24px;
  color: var(--light);
}

.navbar .btn {
  color: white;
  font-size: 14px;
}

.nav-scrolled {
  background-color: var(--dark);
  padding: 17px 0;
}

.nav-scrolled .btn {
  background-color: var(--primary);
}

@media all and (max-width: 768px) {
  .navbar {
    display: none;
  }

  .nav-mobile {
    position: fixed;
    z-index: 9;
    padding: 5%;
    padding-right: 3%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    transition: padding 0.3s ease, background-color 0.3s ease;
  }

  .menu-button {
    width: 50px;
    height: 100%;
    color: white;
  }

  .dropdown-menu {
    position: absolute;
    z-index: 2;
    background-color: var(--dark);
    top: 0;
    left: 0;
    width: 100%;
    padding: 5%;
    border-radius: 0 0 10px 10px;
  }

  .nav-link {
    padding: 20px 0;
    margin-right: 0;
    text-align: center;
    color: var(--light);
  }

  .nav-link:hover {
    color: var(--light);
  }

  .btn-trans {
    text-align: center;
    font-size: 14px;
    color: white;
    margin-top: 10px;
    background-color: var(--primary);
  }

  .btn-trans:hover {
    background-color: var(--primary);
  }

  .trans {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .nav-scrolled {
    padding: 2% 3% 2% 5%;
  }
}

@media all and (min-width: 768px) {
  .nav-mobile {
    display: none;
  }
}
