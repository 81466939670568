@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;0,800;1,400;1,600;1,700;1,800&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }
body { line-height: 1; }

ol, ul { list-style: none; }
blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

:root {
  --light: #f6f1f1;
  --light-30: #f6f1f14d;
  --dark: #463a37;
  --primary: #a87c4f;
}

body {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f1f1;
  background-color: var(--light);
}

.container {
  margin-left: 140px;
  margin-right: 140px;
}

.btn {
  padding: 20px 33px;
  border-radius: 50px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn:active {
  opacity: 0.7;
}

.btn-trans {
  background-color: #f6f1f14d;
  background-color: var(--light-30);
}

.btn-trans:hover {
  background-color: #a87c4f;
  background-color: var(--primary);
}

.btn-group {
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

.btn-group .btn {
  margin-right: 20px;
}

.btn-group .btn:last-of-type {
  margin-right: 0;
}

.btn-primary {
  background-color: #a87c4f;
  background-color: var(--primary);
  color: white;
}

.btn-ghost {
  border: solid 1px white;
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Playfair Display', serif;
}

h1 {
  font-size: 48px;
  line-height: 53px;
}

h2 {
  font-size: 40px;
  line-height: 132.5%;
}

h3 {
  font-size: 32px;
  line-height: 24px;
}

p {
  line-height: 150%;
  opacity: 0.7;
}

a {
  color: unset;
  text-decoration: unset;
}

@media all and (max-width: 768px) {
  .container {
    width: 90%;
    margin-left: 0;
    margin-right: 0;
    margin: auto;
  }

  h1 {
    font-size: 44px;
  }

  h2 {
    font-size: 36px;
    line-height: 110%;
  }

  p {
    line-height: 175%;
  }
}

.fade-in {
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-1-11 1:35:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.slide-in-top {
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-1-11 1:34:20
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-out {
  animation: fade-out 1s ease-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-1-11 1:41:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.slide-out-top {
  animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-1-11 1:41:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */
@keyframes slide-out-top {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-1000px);
    opacity: 0;
  }
}


.hero {
  background-image: url(/static/media/hero-bg.6263655e.jpg);
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 70px;
}

.hero .container {
  text-align: center;
}

.hero-content {
  color: white;
  max-width: 600px;
  margin: auto;
}

.hero-content h1 {
  margin-bottom: 20px;
}

.sub-title {
  margin-bottom: 30px;
}

@media all and (max-width: 768px) {
  .hero {
    margin-bottom: 0;
  }

  .hero .btn {
    padding: 20px 28px;
  }
}

.more-than {
  padding: 70px 0;
}

.more-than .container {
  display: flex;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02), 0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05), 0 100px 80px rgba(0, 0, 0, 0.07);
  overflow: hidden;
}

.square {
  width: 100%;
  max-width: 545px;
  height: 545px;
  background: url(/static/media/more-than.fe565732.jpg);
  background-size: cover;
  background-position: top;
}

.rest {
  padding: 80px;
}

.rest h2 {
  margin-bottom: 20px;
  position: relative;
  margin-left: -5px;
}

.rest p {
  margin-top: 15px;
}

@media all and (max-width: 768px) {
  .more-than .container {
    flex-direction: column;
  }

  .rest {
    padding: 10%;
  }

  .rest h2 {
    position: relative;
    margin-left: -1px;
  }

  .more-than .container {
    box-shadow: unset;
  }
}

.stylists {
  padding-top: 70px;
  padding-bottom: 70px;
}

.stylists .container {
  display: flex;
  min-height: 300px;
}

.stylists h2 {
  position: relative;
  left: -5px;
  margin-bottom: 18px;
}

.stylists-content {
  display: flex;
  align-items: center;
}

.stylists .photos-container {
  position: relative;
  min-width: 818px;
  max-width: 818px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  left: -68px;
}

.stylists .slider {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 684px;
  max-width: 684px;
  scroll-behavior: smooth;
}

.stylists .slider::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.stylists .photo-wrapper {
  min-width: 240px;
  max-width: 240px;
  height: 300px;
  margin-right: 18px;
  border-radius: 5px;
}

.stylists .photo-wrapper:last-of-type {
  margin-right: 0;
}

.stylists .photo-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.stylists .pn-btn {
  margin-bottom: 16px;
  cursor: pointer;
  position: relative;
  left: 0;
  transition: left 0.3s ease;
}

.stylists .pn-btn:active {
  opacity: 0.7;
}

.stylists .prev-btn {
  transform: rotate(180deg);
}

.stylists .prev-btn:hover {
  left: -3px;
}

.stylists .next-btn:hover {
  left: 3px;
}

@media all and (max-width: 768px) {
  .stylists {
    /* padding-bottom: 0; */
    background-color: white;
  }

  .stylists h2 {
    left: 0;
  }

  .stylists .container {
    flex-direction: column-reverse;
  }

  .stylists .photos-container,
  .stylists .slider {
    min-width: 100%;
    max-width: 100%;
  }

  .stylists .pn-btn {
    display: none;
  }

  .stylists .photos-container {
    left: 0;
  }

  .stylists-content {
    /* padding: 5%; */
  }
}

.customers {
  padding-top: 70px;
  padding-bottom: 70px;
}

.customers .container {
  display: flex;
}

.customers h2 {
  margin-bottom: 18px;
}

.customers-content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.customers .photos-container {
  position: relative;
  min-width: 818px;
  max-width: 818px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  left: 68px;
}

.customers .slider {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 684px;
  max-width: 684px;
  scroll-behavior: smooth;
}

.customers .slider::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.customers .photo-wrapper {
  min-width: 240px;
  max-width: 240px;
  height: 300px;
  margin-right: 18px;
  border-radius: 5px;
}

.customers .photo-wrapper:last-of-type {
  margin-right: 0;
}

.customers .photo-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.customers .pn-btn {
  margin-bottom: 16px;
  cursor: pointer;
  position: relative;
  left: 0;
  transition: left 0.3s ease;
}

.customers .pn-btn:active {
  opacity: 0.7;
}

.customers .prev-btn {
  transform: rotate(180deg);
}

.customers .prev-btn:hover {
  left: -3px;
}

.customers .next-btn:hover {
  left: 3px;
}

@media all and (max-width: 768px) {
  .customers {
    padding-bottom: 0;
  }

  .customers .container {
    flex-direction: column;
  }

  .customers .photos-container,
  .customers .slider {
    min-width: 100%;
    max-width: 100%;
  }

  .customers .pn-btn {
    display: none;
  }

  .customers .photos-container {
    left: 0;
  }

  .customers-content {
    /* padding: 5%; */
    /* text-align: right; */
  }
}

.map-section {
  padding-top: 70px;
  padding-bottom: 70px;
}

.map-section .container {
  display: flex;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02), 0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05), 0 100px 80px rgba(0, 0, 0, 0.07);
}

.map-section h3 {
  color: var(--dark);
}

.map-container {
  width: 100%;
  max-width: 545px;
  height: 545px;
}

#map {
  width: 100%;
  height: 100%;
  border-radius: 0 5px 5px 0;
}

.contactDetails {
  flex-grow: 1;
  padding: 0 86px;
  max-height: 545px;
  display: flex;
  align-items: center;
}

.oneDetail {
  margin-bottom: 70px;
}

.oneDetail p {
  max-width: 228px;
  margin-top: 17px;
}

.oneDetail:last-of-type {
  margin-bottom: 0;
}

.map-section a {
  transition: color 0.3s ease;
}

.map-section a:hover {
  color: var(--primary);
}

.map-section a:active {
  opacity: 0.7;
}

@media all and (max-width: 768px) {
  .map-section .container {
    flex-direction: column;
    overflow: hidden;
  }

  .contactDetails {
    padding: 15% 10%;
  }

  .oneDetail {
    margin-bottom: 55px;
  }

  #map {
    border-radius: 0 0 5px 5px;
  }
}

.navbar {
  padding: 35px 0;
  position: fixed;
  z-index: 9;
  top: 0;
  width: 100vw;
  background-color: transparent;
  transition: padding 0.3s ease, background-color 0.3s ease;
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .btn {
  font-size: 14px;
}

.link-container {
  display: flex;
  align-items: center;
}

.nav-link {
  color: white;
  margin-right: 50px;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: color 0.1s ease;
}

.nav-link:hover {
  color: var(--primary);
}

.nav-link:active {
  opacity: 0.7;
}

.nav-brand {
  font-family: 'Playfair Display', serif;
  font-size: 24px;
  color: var(--light);
}

.navbar .btn {
  color: white;
  font-size: 14px;
}

.nav-scrolled {
  background-color: var(--dark);
  padding: 17px 0;
}

.nav-scrolled .btn {
  background-color: var(--primary);
}

@media all and (max-width: 768px) {
  .navbar {
    display: none;
  }

  .nav-mobile {
    position: fixed;
    z-index: 9;
    padding: 5%;
    padding-right: 3%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    transition: padding 0.3s ease, background-color 0.3s ease;
  }

  .menu-button {
    width: 50px;
    height: 100%;
    color: white;
  }

  .dropdown-menu {
    position: absolute;
    z-index: 2;
    background-color: var(--dark);
    top: 0;
    left: 0;
    width: 100%;
    padding: 5%;
    border-radius: 0 0 10px 10px;
  }

  .nav-link {
    padding: 20px 0;
    margin-right: 0;
    text-align: center;
    color: var(--light);
  }

  .nav-link:hover {
    color: var(--light);
  }

  .btn-trans {
    text-align: center;
    font-size: 14px;
    color: white;
    margin-top: 10px;
    background-color: var(--primary);
  }

  .btn-trans:hover {
    background-color: var(--primary);
  }

  .trans {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .nav-scrolled {
    padding: 2% 3% 2% 5%;
  }
}

@media all and (min-width: 768px) {
  .nav-mobile {
    display: none;
  }
}

.footer {
  background-color: var(--dark);
  color: white;
  padding: 35px 0;
}

.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer a {
  transition: -webkit-filter 0.3s ease;
  transition: filter 0.3s ease;
  transition: filter 0.3s ease, -webkit-filter 0.3s ease;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.footer a:hover {
  -webkit-filter: invert(50%) sepia(59%) saturate(343%) hue-rotate(349deg) brightness(91%) contrast(88%);
          filter: invert(50%) sepia(59%) saturate(343%) hue-rotate(349deg) brightness(91%) contrast(88%);
}

.footer a:active {
  opacity: 0.7;
}

@media all and (max-width: 768px) {
  .footer .container {
    flex-direction: column-reverse;
  }

  .copyright {
    font-size: 14px;
    margin-top: 17px;
  }
}

